import Component from 'gia/Component';
import eventbus from 'gia/eventbus';
 
class Dropzone extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            fileInput: null,
        }

        this.registerEventBus();
    }

    mount() {
        this.bindEventListeners();
        this.registerEventListeners();
    }

    /**
     * Register event bus
     */
    registerEventBus() {
        eventbus.on('dropzone::openFileInput', (data) => this.ref.fileInput.click());
    }

    /**
     * Bind only event listeners which need to be removed
     */
    bindEventListeners() {}

    /**
     * Register event listeners
     */
    registerEventListeners() {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach((event) => {
            document.body.addEventListener( event, (evt) => {
                // preventing the unwanted behaviours
                evt.preventDefault();
                evt.stopPropagation();
            });
        });

        ['dragover', 'dragenter'].forEach((event) => {
            document.body.addEventListener(event, (evt) => {
                this.element.classList.add('is-dragover');
            });
        });

        [ 'dragleave', 'dragend', 'drop' ].forEach((event) => {
            document.body.addEventListener( event, (evt) => {
                this.element.classList.remove('is-dragover');
            });
        });

        document.body.addEventListener('drop', (evt) => {
            this.onFilesSelected(evt.dataTransfer.files);
        });

        this.ref.fileInput.addEventListener('change', (evt) => {
			this.onFilesSelected(evt.target.files);	
		});

        this.element.addEventListener('click', (evt) => {
			this.ref.fileInput.click();	
		});
    }
    
    onFilesSelected(files) {
        const file = files[0];
        if (!file || !file.type.startsWith('image/') ) {
            return;
        }

        eventbus.emit('dropzone::fileSelected', { file });
        this.element.hidden = true;
        document.body.classList.add('is-editing');
    }
}

export default Dropzone;
