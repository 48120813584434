// import '../css/style.css';

import loadComponents from 'gia/loadComponents';
import config from 'gia/config';
import Dropzone from './components/dropzone.js';
import Selectors from './components/selectors.js';
import Croptool from './components/croptool.js';
import { onDomReady } from './helpers/utils.js';

export default class App {
	constructor() {
		if (!PRODUCTION) {}

		config.set('log', !PRODUCTION);
	
		loadComponents({
			Dropzone,
			Selectors,
			Croptool
		});
	}
}

onDomReady(() => {
	const app = new App();
});
